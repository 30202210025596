export enum PermissionTypes {
  AD_FREE = 'sl_no-ads',
  AD_FREE_CAMS = 'sl_no-camera-ads',
  AD_SUPPORTED_CAMS = 'sl_reduced-camera-ads',
  BUOY_SPECTRA = 'sl_buoy-swell-spectra',
  CAM_INSIGHTS = 'sl_camera-insights',
  CAM_REWINDS = 'sl_cam-rewinds',
  CORE_16DAY_FORECAST = 'sl_core-16day-forecast',
  CUSTOM_FORECAST = 'sl_custom-forecasts',
  EXTENDED_CHART_LAYERS = 'sl_extended-chart-layers',
  EXTENDED_TIDES = 'sl_extended-tides',
  GRAPH_REWINDS = 'sl_rewinds-graph',
  HIGH_VALUE_CAMERA_ACCESS = 'sl_high-value-camera-access',
  HISTORIC_FORECAST = 'sl_historic-forecast',
  LINEUP = 'sl_lineup',
  LIVE_WIND = 'sl_live-wind',
  MULTI_CAM = 'sl_multi-cam',
  PREMIUM_ANALYSIS = 'sl_forecast-analysis',
  SMART_CLIPS = 'sl_smart-clips',
  SURF_REPORT_TEXT = 'sl_surf-report-text',
  SWELL_SPECTRA = 'sl_spot-swell-spectra',
  WAVE_CONSISTENCY = 'sl_wave-consistency',
  WAVE_ENERGY = 'sl_wave-energy',
}

export interface Permission {
  name: PermissionTypes;
  resource: string;
  resourceType: string;
  role: string;
}
