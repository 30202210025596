import { getSpotMapData } from '../common/fetch';

export const FETCH_SPOT_MAP_DATA = 'FETCH_SPOT_MAP_DATA';
export const FETCH_SPOT_MAP_DATA_SUCCESS = 'FETCH_SPOT_MAP_DATA_SUCCESS';
export const FETCH_SPOT_MAP_DATA_FAILURE = 'FETCH_SPOT_MAP_DATA_FAILURE';

export const fetchSpotMapData =
  ({ north, south, east, west }, doCluster = false, observationClarity = false) =>
  async (dispatch, state) => {
    dispatch({
      type: FETCH_SPOT_MAP_DATA,
    });

    try {
      const spotMapData = await getSpotMapData({ north, south, east, west, observationClarity });
      dispatch({
        type: FETCH_SPOT_MAP_DATA_SUCCESS,
        spots: spotMapData.data.spots,
        regionalForecast: spotMapData.data.regionalForecast,
        subregions: spotMapData.data.subregions,
        units: spotMapData.units,
        zoom: state().map.location.zoom,
        doCluster,
      });
    } catch (error) {
      dispatch({
        type: FETCH_SPOT_MAP_DATA_FAILURE,
        error: error.message,
      });
    }
  };
