import 'isomorphic-fetch';
import { nrNoticeError } from '@surfline/web-common';
import baseFetch from './baseFetch';
import config from '../config';

export const getSpotMapData = async ({ north, south, east, west, observationClarity }) => {
  // TODO: Move this to config
  let url = `/kbyg/mapview?south=${south}&west=${west}&north=${north}&east=${east}`;

  // Only include observationClarity when it's true
  if (observationClarity === true) {
    url = `${url}&observationClarity=true`;
  }

  return baseFetch(url);
};

export const getUserRegion = async () => {
  // TODO: Move this to config
  const url = '/geo-target/region?';
  return baseFetch(url);
};

export const getNearestSpot = async ({ lat, lon, observationClarity }) => {
  // TODO: Move this to config
  let url = `/kbyg/mapview/spot?lat=${lat}&lon=${lon}`;

  // Only include observationClarity when it's true
  if (observationClarity === true) {
    url = `${url}&observationClarity=true`;
  }

  return baseFetch(url);
};

export const fetchLocationMapView = async (geonameId) => {
  const url = `${config.servicesURL}/location/view?type=mapView&id=${geonameId}`;

  const response = await fetch(url);

  const body = await response.json();

  if (response.status === 400) {
    throw response;
  }

  if (response.status > 200) {
    nrNoticeError(new Error(response.statusText));
    throw body;
  }
  return body;
};
